<template>
  <div class="p-1">
    <h3>Add Training</h3>
    <b-form class="mt-1">
      <div class="form_elements">
        <b-form-group label="Name">
          <b-form-input placeholder="Name" v-model="form_data.tr_heading" />
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea
            placeholder="Description"
            v-model="form_data.tr_description"
            rows="3"
          />
        </b-form-group>
        <b-form-group label="Link">
          <b-form-input placeholder="Link" v-model="form_data.tr_link" />
        </b-form-group>
        
        <b-form-group label="Date">
          
          <flat-pickr
            v-model="form_data.tr_date"
            class="form-control"
            placeholder="Select Date and Time"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i',
            }"
          />
        </b-form-group>
        <!-- <b-form-group label="Select User Type">
          <v-select
            v-model="form_data.tr_selectedUser"
            :options="userTypes"
            label="user_type_name"
            multiple
            class=""
            placeholder="Select User Type"
          />
        </b-form-group> -->
        <b-form-group label="Email Subject">
          <b-form-input placeholder="Email Subject" v-model="form_data.tr_email_subject" />
        </b-form-group>
        <b-form-group label="Email Description">
          <b-form-textarea placeholder="Email Description" v-model="form_data.tr_email_description" />
        </b-form-group>
        <!-- <b-form-group label="Is Training Active">
          <v-select
            v-model="form_data.is_active"
            :options="[
              { value: 'Y', label: 'Yes' },
              { value: 'N', label: 'No' },
            ]"
            label="label"
            :reduce="(item) => item.value"
            class="mb-2"
            placeholder="Is Training Active"
          />
        </b-form-group> -->
      </div>

      <div class="buttons">
        <b-button variant="outline-success" size="md" @click="saveTraining"
          >Save</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import CommonServices from "@/apiServices/CommonServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";


import {
  BAvatar,
  BListGroup,
  BListGroupItem,
  BCardText,
  BForm,
  BFormInput,
  BButton,
  BFormDatepicker,
  BFormCheckbox,
  BFormSelect,
  BFormTextarea,
  BFormGroup,
} from "bootstrap-vue";
import CounselorServices from "@/apiServices/CounselorServices";

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BForm,
    BFormInput,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BFormSelect,
    vSelect,
    BFormTextarea,
    BFormGroup,
    flatPickr
  },
  data() {
    return {
      form_data: {
        tr_id: null,
        tr_heading: "",
        tr_description: "",
        tr_date: "",
        tr_link: "",
        tr_type: "aliff",
        tr_selectedUser: [],
      },
      userTypes: [],
    };
  },

  methods: {
    async saveTraining() {
      // if (
      //   !this.form_data.tr_heading ||
      //   !this.form_data.tr_description ||
      //   !this.form_data.tr_date ||
      //   !this.form_data.tr_link ||
      //   !this.form_data.tr_email_subject ||
      //   !this.form_data.tr_email_description ||
      //   !this.form_data.tr_selectedUser.length
      // ) {
      //   this.$toast({
      //     component: ToastificationContentVue,
      //     props: {
      //       title: "Error",
      //       icon: "AlertTriangleIcon",
      //       variant: "danger",
      //       text: "Please fill all the required fields",
      //     },
      //   });
      //   return;
      // }
      try {
        const res = await CommonServices.addTraining(this.form_data);
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Training Added Successfully",
              icon: "Check",
              variant: "success",
              text: res.data.message,
            },
          });
          this.form_data = {
            tr_id: null,
            tr_heading: "",
            tr_description: "",
            tr_date: "",
            tr_link: "",
            tr_type: "aliff",
            tr_email_subject: "",
            tr_email_description: "",
            // tr_selectedUser: [],
          };
          this.$emit("refresh");
          this.$root.$emit("bv::toggle::collapse", "sidebar-right");
        }
        // console.log(this.form_data);
      } catch (error) {
        console.error("Error in saveTraining ", error);
      }
    },
    updateForm(trainingData) {
      this.form_data = {
        tr_id: trainingData.tr_id,
        tr_heading: trainingData.tr_heading,
        tr_description: trainingData.tr_description,
        tr_date: new Date(trainingData.tr_date),
        tr_link: trainingData.tr_link,
        tr_type: trainingData.tr_type,
        is_active: trainingData.is_active,
        tr_email_subject: trainingData.tr_email_subject,
        tr_email_description: trainingData.tr_email_description,
        // tr_selectedUser: trainingData.tr_selectedUser,
      };
    },
    async getUserTypes() {
      try {
        const res = await CounselorServices.getUserTypes();
        if (res.data.status) {
          this.userTypes = res.data.data.all_types;
          console.log(this.userTypes);
        }
      } catch (error) {
        console.error(`Error in  getting user types `, error);
      }
    },
  },

  computed: {
    user_type() {
      return this.$store.getters["user/getUserDetails"].user_type;
    },
  },
  beforeMount() {
    if (this.user_type === "counselor") {
      this.getUserTypes();
    }
  },
};
</script>

<style>
.form_elements {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>

