<template>
 <div class="shadow rounded">
  <b-card no-body class="card-developer-meetup">
    <div
      class="bg-light-primary rounded-top text-center cursor-pointer"
      @click="goToTraining(item.tr_id)"
    >
      <b-img
        :src="require('@/assets/images/illustration/email.svg')"
        alt="Meeting Pic"
        height="170"
      />
    </div>
    <b-card-body>
      <div class="description">
        <h3>{{ item.tr_heading }}</h3>
        <p class="text-md mt-1 desc__text" v-html="item.tr_description"></p>
      </div>

      <div class="icons_with_text mt-2">
        <div class="date d-flex align-items-center">
          <feather-icon
            icon="CalendarIcon"
            size="25"
            stroke="#fff"
            fill="#333"
          />
          <span class="ml-1" style="font-size: medium">
            {{
              new Intl.DateTimeFormat("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              }).format(new Date(item.tr_date))
            }}</span
          >
        </div>

        <div class="time d-flex align-items-center mt-2">
          <feather-icon icon="ClockIcon" size="25" stroke="#fff" fill="#333" />
          <span class="ml-1" style="font-size: medium">
            {{
              new Date(item.tr_date).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            }}
          </span>
        </div>

        <div class="venue d-flex align-items-center mt-2">
          <feather-icon icon="MapPinIcon" size="25" stroke="#fff" fill="#333" />
          <span class="ml-1" style="font-size: medium">
            Online
            <a
              @click="openLink(item.tr_link)"
              target="_blank"
              rel="noopener noreferrer"
              style="
                font-size: small;
                margin-left: 2px;
                text-decoration: underline;
                color: blue;
              "
            >
              Join Now</a
            >
          </span>
        </div>
      </div>

      <!-- Response Section  -->
      <div class="users mt-2 d-flex justify-content-between">
        <div class="total d-flex align-items-center">
          <feather-icon icon="UsersIcon" size="25" stroke="#333" />
          <span class="ml-1" style="font-size: medium">
            Responses :
            <span style="font-size: large"> {{ total_count }} </span>
          </span>
        </div>

        <div class="total d-flex justify-content-between">
          <div class="confirmed d-flex align-items-center">
            <feather-icon
              icon="CheckCircleIcon"
              size="20"
              stroke="#333"
              fill="#28C76F"
            />
            <span class="" style="font-size: medium; font-weight: bold">
              {{ confirmed_count }}
            </span>
          </div>
          <div class="unconfirmed d-flex align-items-center ml-1">
            <feather-icon
              icon="HelpCircleIcon"
              size="20"
              stroke="#333"
              fill="#F7DC6F"
            />
            <span class="" style="font-size: medium; font-weight: bold">
              {{ maybe_count }}
            </span>
          </div>
          <div class="unconfirmed d-flex align-items-center ml-1">
            <feather-icon
              icon="XCircleIcon"
              size="20"
              stroke="#333"
              fill="#FF5F6D"
            />
            <span class="" style="font-size: medium; font-weight: bold">
              {{ no_count }}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex mt-3 justify-content-between" style="height: 30px">
          <div>
            <div v-if="documentPresent">
              <div>
                <feather-icon
                  icon="FileTextIcon"
                  size="20"
                  stroke="#333"
                  fill="#fff"
                  class="cursor-pointer"
                  @click="getTrainingMaterials(item.tr_id)"
                />
                <span class="ml-0.5" style="font-size: small; color: grey"
                  >Material
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="
              user_type === 'franchise' &&
              checkIfPresent &&
              new Date(item.tr_date.replace(' ', 'T')) >= new Date()
            "
          >
            <b-button size="sm" :variant="btn_variant" disabled>
              {{ btn_text }}
            </b-button>
          </div>
          <div
            class="edit_btn_div"
            v-if="user_type === 'counselor' && type === 'aliff'"
          >
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
              size="21"
              @click="openSidebar(item.tr_id)"
            />
          </div>
          <div
            class="d-flex justify-content-end"
            v-if="
              user_type === 'franchise' &&
              !checkIfPresent &&
              new Date(item.tr_date.replace(' ', 'T')) >= new Date()
            "
          >
            <b-button
              size="sm"
              variant="outline-primary"
              @click="handleregister(item.tr_id, 'Y')"
            >
              Yes
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-1"
              @click="handleregister(item.tr_id, 'M')"
            >
              Maybe
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-1"
              @click="handleregister(item.tr_id, 'N')"
            >
              No
            </b-button>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
 </div>
</template>
<script>
import {
  BCard,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
  BButton,
} from "bootstrap-vue";
import { FILESURL } from "@/config";
import CommonServices from "@/apiServices/CommonServices";
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: null,
      btn_variant: "secondary",
      btn_text: "Registered",
      file_data: null,
      training_material_data: null,
      // tr_id: null,
      no_material: false,
      file_data_is_there: false,
    };
  },
  methods: {
    isRegistered(registeredList) {
      if (!registeredList || !Array.isArray(registeredList)) {
        return false;
      }
      return registeredList.some((item) => item.user_id === this.user_id);
    },
    openSidebar(trainingId) {
      this.$emit("open-sidebar", trainingId);
    },
    openListSideBar(regList) {
      this.$emit("open-list-sidebar", regList);
    },
    handleregister(trainingId, option) {
      this.$emit("handle-open-register-modal", trainingId, option);
    },
    goToTraining(training_id) {
      this.$router.push({
        path: `/training_details`,
        query: {
          id: training_id,
        },
      });
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    getVariant(student) {
      if (this.item.confirmed_users.includes(student)) {
        this.btn_text = "Already Registered";
        this.btn_variant = "success";
        return "secondary";
      } else if (this.item.maybe_users.includes(student)) {
        this.btn_text = "Maybe";
        this.btn_variant = "secondary";
        return "secondary";
      } else if (this.item.no_users.includes(student)) {
        this.btn_text = "Not Registered";
        this.btn_variant = "danger";
        return "secondary";
      }
    },
    getTitle(student) {
      if (this.item.confirmed_users.includes(student)) {
        return `<div>${student.user_fullname}<span class="text-success ml-1">&#10004;</span></div>`;
      } else if (this.item.maybe_users.includes(student)) {
        return `<div>${student.user_fullname}<span class="text-white ml-1">?</span></div>`;
      }
      return `<div>${student.user_fullname}<span class="text-danger ml-1 font-weight-bold" >X</span></div>`;
    },
    async getTrainingMaterials(trainingId) {
      try {
        console.log(trainingId);
        const res = await CommonServices.getTrainingMaterials({
          tr_id: trainingId,
        });
        if (res.data.status) {
          if (res.data.data.length === 0) {
            this.no_material = true;
          }
          const material = res.data.data[0];
          if (material.youtube_link !== null) {
            this.training_material_data = material.youtube_link;
            window.open(this.training_material_data, "_blank");
          } else if (material.file_link !== null) {
            this.training_material_data = material.file_link;
            window.open(this.training_material_data, "_blank");
          } else if (material.uploaded_file !== null) {
            this.training_material_data = material.uploaded_file;
            this.file_data_is_there = true;
            window.open(this.FILESURL + this.training_material_data, "_blank");
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    user_type() {
      return this.$store.getters["user/getUserDetails"].user_type;
    },
    user_id() {
      return this.$store.getters["user/getUserDetails"].user_id;
    },
    checkIfPresent() {
      return (
        (
          this.item.confirmed_users &&
          this.item.confirmed_users.filter(
            (user) => user.user_id === this.user_id
          )
        ).length > 0 ||
        (
          this.item.maybe_users &&
          this.item.maybe_users.filter((user) => user.user_id === this.user_id)
        ).length > 0 ||
        (
          this.item.no_users &&
          this.item.no_users.filter((user) => user.user_id === this.user_id)
        ).length > 0
      );
    },
    combinedList() {
      return [
        ...(this.item.confirmed_users || []),
        ...(this.item.maybe_users || []),
        ...(this.item.no_users || []),
      ];
    },

    total_count() {
      return Array.isArray(this.combinedList) ? this.combinedList.length : 0;
    },
    confirmed_count() {
      return Array.isArray(this.item.confirmed_users)
        ? this.item.confirmed_users.length
        : 0;
    },
    maybe_count() {
      return Array.isArray(this.item.maybe_users)
        ? this.item.maybe_users.length
        : 0;
    },
    no_count() {
      return Array.isArray(this.item.no_users) ? this.item.no_users.length : 0;
    },

    documentPresent() {
      return this.item.tr_material && this.item.tr_material.length > 0;
    },
  },
  beforeMount() {
    // this.$emit("refresh");
  },
};
</script>
<style scoped>
/* .register_btn_div {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.meetup-header .mb-0 {
  }
  .edit_btn_div {
    position: absolute;
    bottom: 10px;
    right: 10px;
    }*/
/* .card-developer-meetup {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);

} */
.description .desc__text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
